import { AppContext } from "@/app/context"
import { PackageContext } from "@/app/context/PackageContext"
import { removeStyle } from "@/app/helpers/function"
import { IGiftcode } from "@/app/interfaces/IGiftcode"
import { Trans, useTranslation } from "next-i18next"
import Image from "next/image"
import TagImage from "public/img/store/tag.png"
import FireImage from "public/img/store/fire.png"
import { FC, useContext } from "react"
import { Button } from "../ui/button"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

interface CardGiftcodeProps {
  item: IGiftcode
  redirect?: string
}

export const CardGiftcode: FC<CardGiftcodeProps> = ({ item, redirect }) => {
  const { themeApp, setVisibleGiftcodeDetail, setSelectedGiftcode } =
    useContext(AppContext)
  const { t } = useTranslation()
  const { setVisible, visible, setDataSelected } = useContext(PackageContext)
  const random =
    item.total_giftcode - item.total_received > 0
      ? item.total_giftcode - item.total_received
      : 1
  const GAEventsTracker = useGAEventsTracker("Giftcode")

  return (
    <div
      key={`${item?.id}-game-detail-giftcode`}
      className="w-full"
      onClick={() => {
        if (redirect === "store") {
          GAEventsTracker("Store_giftcode_get_now")
        } else {
          GAEventsTracker("Giftcode_get_now")
        }
        setVisibleGiftcodeDetail(true)
        setSelectedGiftcode(item)
      }}
    >
      <div
        className={`h-[120px] overflow-hidden cursor-pointer flex rounded-[14px] w-full bg-white dark:bg-white/10`}
      >
        <div
          className={`px-[14px] flex items-center py-[10px] border  border-r-0 rounded-tl-[14px] rounded-bl-[14px] border-black/10 dark:border-white/10 relative`}
        >
          <Image
            alt={item?.giftcode_description}
            src={item?.game_icon}
            width={72}
            height={72}
            className="rounded-[10px]"
          />
          {item?.type === "newbie" && (
            <Image
              src={TagImage}
              width={25}
              height={23}
              alt="tag"
              className="absolute left-[11px] top-[18px]"
            />
          )}
        </div>
        <div
          className={`w-[1px] h-full border border-dashed border-giftcode relative border-black/10 dark:border-neutral-08`}
          data-activeid={themeApp === "dark" ? "#ffffff1a" : "#0000001A"}
        ></div>
        <div
          className={`flex flex-col border border-l-0 flex-1 rounded-tr-[14px] rounded-br-[14px] px-[14px] py-[10px] border-black/10 dark:border-white/10`}
        >
          <p
            className="line-clamp-2 text-[14px] Nunito-600 leading-[19px] pb-[2px]"
            title={item?.title}
          >
            {item?.title}
          </p>
          <p
            className="line-clamp-1 text-neutral-06 dark:text-neutral-07 text-[12px] leading-[16px]"
            // dangerouslySetInnerHTML={{
            //   __html: removeStyle(item?.description || "") || ""
            // }}
          >
            {item?.game_name}
          </p>
          <div className="flex items-center justify-between gap-[8px] mt-auto mb-0 relative">
            {item?.type === "newbie" && item?.total_giftcode && (
              <Image
                src={FireImage}
                alt="fire"
                style={{
                  zIndex: 2
                }}
                className="absolute top-[2px] left-[-6px]"
              />
            )}
            {item?.type === "newbie" && item?.total_giftcode ? (
              <div className="flex-1 w-full h-[16px] rounded-[16px] bg-[#EB737366] flex items-center justify-end xs:justify-center pr-3 xs:pr-0 relative overflow-hidden">
                <div
                  style={{
                    zIndex: 1,
                    width: `${
                      ((random === item?.total_giftcode ? 0 : random) * 100) /
                      item?.total_giftcode
                    }%`,
                    background:
                      "linear-gradient(90deg, #F64A46 0%, #F5AA92 100%)"
                  }}
                  className={`absolute left-0 top-0 h-full rounded-tl-[16px] rounded-bl-[16px]`}
                ></div>

                <p
                  style={{
                    zIndex: 2
                  }}
                  className="text-[10px] text-white uppercase Nunito-700"
                >
                  <Trans
                    i18nKey={t("common.gc_remain", {
                      number: random
                    })}
                  />
                </p>
              </div>
            ) : (
              <div className="text-neutral-06 dark:text-neutral-07 text-[12px]">
                <Trans
                  i18nKey={t("common.gc_received", {
                    number: item?.total_received
                  })}
                  components={[<span className="Nunito-700" key={0}></span>]}
                />
              </div>
            )}

            <Button
              variant="main"
              className={`text-[12px] Nunito-500 h-[28px] ${
                item.type === "newbie" ? "w-[83px]" : "px-[16px]"
              } rounded-[10px]`}
              onClick={() => {
                setVisibleGiftcodeDetail(true)
                setSelectedGiftcode(item)
              }}
            >
              {t("giftcode.get_now")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
