import { IconGiftcode } from "@/app/components/icon-tsx/IconGiftcode"
import { IconReceivedGiftcode } from "@/app/components/icon-tsx/IconReceivedGiftcode"
import { IconUseGiftcode } from "@/app/components/icon-tsx/IconUseGiftcode"
import { IconVoucherBold } from "@/app/components/icon-tsx/IconVoucherBold"
import { AppContext } from "@/app/context"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useContext } from "react"

export const GiftcodeBanner = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    visibleGiftcodeDetail,
    setVisibleGiftcodeDetail,
    selectedGiftcode,
    setSelectedGiftcode,
    setVisibleUseGiftcode
  } = useContext(AppContext)
  const GAEventsTracker = useGAEventsTracker("Giftcode")

  return (
    <div className="giftcode-banner md:h-[98px] relative md:flex items-center w-full justify-center relative">
      <h1 className="hidden md:block text-white text-[32px] Nunito-800 text-center text-transparent mb-[25px]">
        Giftcode
      </h1>

      <div className="hidden absolute md:flex items-center left-[50%] bottom-[-28px] md:translate-x-[-50%] p-[8px] bg-white dark:bg-dark-mode-box-2 rounded-[24px] shadow-[4px_4px_15px_0_rgba(0,0,0,0.04)] w-[360px] h-[56px]">
        <div
          className="flex-1 h-full cursor-pointer flex items-center justify-center text-[14px] Nunito-600 text-main-01 gap-[6px] active:opacity-60"
          onClick={() => {
            setVisibleUseGiftcode(true)
          }}
        >
          <IconUseGiftcode />
          {t("giftcode.use_giftcode")}
        </div>
        <div className="w-[1px] h-[20px] bg-black/10 dark:bg-white/10"></div>
        <div
          className="flex-1 h-full cursor-pointer flex items-center justify-center text-[14px] Nunito-600 text-main-01 gap-[6px] active:opacity-60"
          onClick={() => {
            router.push("/reward-center?item=giftcode")
          }}
        >
          <IconReceivedGiftcode />
          {t("giftcode.received_giftcode")}
        </div>
      </div>

      <div className="flex md:hidden gap-[8px]">
        <div
          className="flex-1 flex items-center justify-center bg-white dark:bg-white/10 rounded-[12px] h-[36px] text-[12px] Nunito-600 text-main-01 gap-[6px] active:opacity-60 shadow-[4px_4px_15px_0_rgba(0,0,0,0.04)]"
          onClick={() => {
            setVisibleUseGiftcode(true)
            GAEventsTracker("Giftcode_use")
          }}
        >
          <IconUseGiftcode />
          {t("giftcode.use_giftcode")}
        </div>
        <div
          className="flex-1 flex items-center justify-center bg-white dark:bg-white/10 rounded-[12px] h-[36px] text-[12px] Nunito-600 text-main-01 gap-[6px] active:opacity-60 shadow-[4px_4px_15px_0_rgba(0,0,0,0.04)]"
          onClick={() => {
            router.push("/reward-center?item=giftcode")
            GAEventsTracker("Giftcode_redeemed")
          }}
        >
          <IconReceivedGiftcode />
          {t("giftcode.received_giftcode")}
        </div>
      </div>
    </div>
  )
}
