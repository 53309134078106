import { CardGiftcode } from "@/app/components/card-giftcode"
import { IconArrowLeft } from "@/app/components/icon-tsx/IconArrowLeft"
import { IconArrowRight } from "@/app/components/icon-tsx/IconArrowRight"
import { GC_TYPE } from "@/app/configs"
import { IGiftcode } from "@/app/interfaces/IGiftcode"
import { useQueryGetListGiftcode } from "@/app/services/query-fn/giftcode/giftcode-query"
import { useTranslation } from "next-i18next"
import { useRef } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Pagination, Navigation])

export const HotGiftcode = () => {
  const { t } = useTranslation()
  const navigationPrevRef = useRef<HTMLButtonElement>(null)
  const navigationNextRef = useRef<HTMLButtonElement>(null)

  const { data } = useQueryGetListGiftcode({
    style_list: GC_TYPE.IS_HOT
  })

  return data && data?.length > 0 ? (
    <div className="mt-[20px] md:mt-[32px]">
      <div className="flex justify-between items-center pb-[8px]">
        <h2 className="Nunito-700 text-[16px] flex items-center gap-2 text-[#EB7373]">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.4">
              <path
                d="M17 4V9.5H7V4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4Z"
                fill="#EB7373"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22C16.4183 22 20 18.4183 20 14C20 9.58172 16.4183 6 12 6C7.58172 6 4 9.58172 4 14C4 18.4183 7.58172 22 12 22ZM13.3432 11.6805L12.7869 10.5086C12.465 9.83048 11.535 9.83048 11.2131 10.5086L10.6568 11.6805C10.529 11.9497 10.282 12.1364 9.99615 12.1796L8.75226 12.3675C8.03253 12.4762 7.74515 13.3958 8.26595 13.9236L9.16603 14.8358C9.37284 15.0454 9.46722 15.3474 9.4184 15.6434L9.20591 16.9314C9.08297 17.6767 9.83536 18.2451 10.4791 17.8932L11.5917 17.285C11.8473 17.1453 12.1527 17.1453 12.4083 17.285L13.5209 17.8932C14.1646 18.2451 14.917 17.6767 14.7941 16.9314L14.5816 15.6434C14.5328 15.3474 14.6272 15.0454 14.834 14.8358L15.7341 13.9236C16.2549 13.3958 15.9675 12.4762 15.2477 12.3675L14.0038 12.1796C13.718 12.1364 13.471 11.9497 13.3432 11.6805Z"
              fill="#EB7373"
            />
          </svg>
          {t("giftcode.most_used")}
        </h2>
        <div className="hidden lg:flex items-center gap-[6px] ml-auto">
          <button
            className={`p-[2px] rounded-[8px] border border-main-01 cursor-pointer`}
            ref={navigationPrevRef}
          >
            <IconArrowLeft className="xs:w-[16px] xs:h-[16px] w-[20px] h-[20px]" />
          </button>
          <button
            className={`p-[2px] rounded-[8px] border border-main-01 cursor-pointer`}
            ref={navigationNextRef}
          >
            <IconArrowRight className="xs:w-[16px] xs:h-[16px] w-[20px] h-[20px]" />
          </button>
        </div>
      </div>

      {/* list giftcode */}

      <div className="overflow-auto">
        <div className="hidden-scroll-bar">
          <Swiper
            navigation={{
              prevEl: navigationPrevRef.current && navigationPrevRef.current,
              nextEl: navigationNextRef.current && navigationNextRef.current
            }}
            onInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.enable()
              swiper.navigation.update()
            }}
            breakpoints={{
              "1140": {
                spaceBetween: 12,
                slidesPerView: 3
              },
              "768": {
                spaceBetween: 12,
                slidesPerView: 2
              },
              "576": {
                spaceBetween: 12,
                slidesPerView: 1.5
              }
            }}
            slidesPerView={"auto"}
            spaceBetween={8}
            className="w-full gap-[8px]"
          >
            {data?.map((item: IGiftcode) => {
              return (
                <SwiperSlide
                  className="xs:max-w-[320px] w-fit h-full flex"
                  key={`${item?.id}-for-you`}
                >
                  <CardGiftcode item={item} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  ) : null
}
