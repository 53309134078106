import HeaderCustomMobile from "@/app/components/header-custom-mb"
import { AllGiftcode } from "./AllGiftcode"
import { ForYouGiftcode } from "./ForYouGiftcode"
import { GiftcodeBanner } from "./GiftcodeBanner"
import { HotGiftcode } from "./HotGiftcode"
import { useRouter } from "next/router"

export const GiftcodeContainer = () => {
  const router = useRouter()
  const onClose = () => {
    router.push("/store").then()
  }
  return (
    <div className="md:mt-[40px] max-w-[1062px] mx-auto">
      <HeaderCustomMobile isInpage heading="Giftcode" action={onClose} />
      <GiftcodeBanner />
      <ForYouGiftcode />
      <HotGiftcode />
      <AllGiftcode />
    </div>
  )
}
