import { GiftcodeContainer } from "@/app/containers/giftcode"
import { GetServerSideProps, GetServerSidePropsContext } from "next"
import { i18n, withTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

const Giftcode = () => {
  return (
    <>
      <GiftcodeContainer />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  let metaData: any = {
    title: i18n?.t("seo.giftcode_title"),
    desc: i18n?.t("seo.giftcode_description"),
    keyword: i18n?.t("seo.shop_keywords"),
    canonical: `${process.env.NEXT_PUBLIC_HOST_URL}/giftcode`
  }
  return {
    props: {
      // use that for fix error serializing
      metaPage: JSON.parse(JSON.stringify(metaData)) || null,
      ...(await serverSideTranslations(context?.locale ?? "vi-VN", ["common"]))
    }
  }
}

export default withTranslation("common")(Giftcode)
