import React from "react"

export const IconUseGiftcode = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7915 8.99984C1.7915 4.74264 5.24264 1.2915 9.49984 1.2915C12.0213 1.2915 14.26 2.50152 15.6674 4.3752C15.807 4.56103 16.0381 4.65406 16.2675 4.61672C16.4969 4.57938 16.6866 4.41785 16.7601 4.19735L17.5928 1.69735C17.7019 1.36986 17.5248 1.01595 17.1973 0.906867C16.8699 0.797784 16.516 0.974837 16.4069 1.30233L15.9229 2.75522C14.2959 1.08208 12.0193 0.0415039 9.49984 0.0415039C4.55229 0.0415039 0.541504 4.05229 0.541504 8.99984C0.541504 9.34502 0.821326 9.62484 1.1665 9.62484C1.51168 9.62484 1.7915 9.34502 1.7915 8.99984ZM18.4582 8.99984C18.4582 8.65466 18.1783 8.37484 17.8332 8.37484C17.488 8.37484 17.2082 8.65466 17.2082 8.99984C17.2082 13.257 13.757 16.7082 9.49984 16.7082C6.97835 16.7082 4.73967 15.4982 3.33229 13.6245C3.19271 13.4387 2.96155 13.3456 2.73216 13.383C2.50277 13.4203 2.31304 13.5818 2.23959 13.8023L1.40687 16.3023C1.29778 16.6298 1.47484 16.9837 1.80233 17.0928C2.12981 17.2019 2.48372 17.0248 2.59281 16.6973L3.07675 15.2445C4.70379 16.9176 6.98036 17.9582 9.49984 17.9582C14.4474 17.9582 18.4582 13.9474 18.4582 8.99984ZM5.33333 6.5C5.33333 5.57953 6.07953 4.83333 7 4.83333H12C12.9205 4.83333 13.6667 5.57953 13.6667 6.5V11.5C13.6667 12.4205 12.9205 13.1667 12 13.1667H7C6.07953 13.1667 5.33333 12.4205 5.33333 11.5V6.5ZM10.9582 7.33317C10.9582 7.67835 10.6783 7.95817 10.3332 7.95817H8.6665C8.32133 7.95817 8.0415 7.67835 8.0415 7.33317C8.0415 6.98799 8.32133 6.70817 8.6665 6.70817H10.3332C10.6783 6.70817 10.9582 6.98799 10.9582 7.33317Z"
        fill="#7673E6"
      />
    </svg>
  )
}
