import { CardGiftcode } from "@/app/components/card-giftcode"
import { IconArrowLeft } from "@/app/components/icon-tsx/IconArrowLeft"
import { IconArrowRight } from "@/app/components/icon-tsx/IconArrowRight"
import { GC_TYPE } from "@/app/configs"
import { IGiftcode } from "@/app/interfaces/IGiftcode"
import { useQueryGetListGiftcode } from "@/app/services/query-fn/giftcode/giftcode-query"
import { useTranslation } from "next-i18next"
import { useRef } from "react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Pagination, Navigation])

export const ForYouGiftcode = () => {
  const { t } = useTranslation()
  const navigationPrevRef = useRef<HTMLButtonElement>(null)
  const navigationNextRef = useRef<HTMLButtonElement>(null)

  const { data } = useQueryGetListGiftcode({
    style_list: GC_TYPE.FOR_YOU
  })

  return data && data?.length > 0 ? (
    <div className="mt-[20px] md:mt-[60px]">
      <div className="flex justify-between items-center pb-[8px]">
        <h2 className="Nunito-700 text-[16px]">{t("giftcode.for_you")}</h2>
        <div className="hidden lg:flex items-center gap-[6px] ml-auto">
          <button
            className={`p-[2px] rounded-[8px] border border-main-01 cursor-pointer`}
            ref={navigationPrevRef}
          >
            <IconArrowLeft className="xs:w-[16px] xs:h-[16px] w-[20px] h-[20px]" />
          </button>
          <button
            className={`p-[2px] rounded-[8px] border border-main-01 cursor-pointer`}
            ref={navigationNextRef}
          >
            <IconArrowRight className="xs:w-[16px] xs:h-[16px] w-[20px] h-[20px]" />
          </button>
        </div>
      </div>

      {/* list giftcode */}

      <div className="overflow-auto">
        <div className="hidden-scroll-bar">
          <Swiper
            navigation={{
              prevEl: navigationPrevRef.current && navigationPrevRef.current,
              nextEl: navigationNextRef.current && navigationNextRef.current
            }}
            onInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.enable()
              swiper.navigation.update()
            }}
            breakpoints={{
              "1140": {
                spaceBetween: 12,
                slidesPerView: 3
              },
              "768": {
                spaceBetween: 12,
                slidesPerView: 2
              },
              "576": {
                spaceBetween: 12,
                slidesPerView: 1.5
              }
            }}
            slidesPerView={"auto"}
            spaceBetween={8}
            className="w-full gap-[8px]"
          >
            {data?.map((item: IGiftcode) => {
              return (
                <SwiperSlide
                  className="xs:max-w-[320px] w-fit h-full flex"
                  key={`${item?.id}-for-you`}
                >
                  <CardGiftcode item={item} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  ) : null
}
