import { CardGiftcode } from "@/app/components/card-giftcode"
import { DataEmpty } from "@/app/components/data-empty"
import { IconClose } from "@/app/components/icon-tsx/IconClose"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"
import { GC_TYPE, QUERY_KEY } from "@/app/configs"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { IGameGiftcode } from "@/app/interfaces/IGiftcode"
import { useQueryGetListGame } from "@/app/services/query-fn/game"
import {
  useQueryGetListGameGiftcode,
  useQueryGetListGiftcode
} from "@/app/services/query-fn/giftcode/giftcode-query"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"

const HEADER_HEIGHT = 84

export const AllGiftcode = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const [game, setGame] = useState<IGameGiftcode | undefined>({
    game_name: t("common.all"),
    game_id: "all",
    icon: ""
  })
  const queryClient = useQueryClient()
  const GAEventsTracker = useGAEventsTracker("Giftcode")
  const giftcodeRef = useRef<HTMLDivElement>(null)

  const { data: listGame } = useQueryGetListGame({
    category: "",
    type: "",
    limit: 100,
    page: 1
  })
  const { data, isSuccess, isLoading } = useQueryGetListGiftcode({
    style_list: GC_TYPE.BY_GAME,
    game_id:
      router?.query?.game_id === "all"
        ? ""
        : router?.query?.game_id?.toString() || ""
  })

  const OptionGame = !!listGame ? listGame : []

  useEffect(() => {
    if (router.query.game_id && router.query.game_id !== "all") {
      const _game: any = OptionGame?.find(
        (item: any) => item?.game_id === router.query.game_id
      )
      if (_game?.game_id) {
        setGame(_game)
      }
      // else {
      //   setGame({ game_name: t("common.all"), game_id: "all", icon: "" })

      //   router.push({
      //     pathname: router.pathname,
      //     query: {
      //       game_id: "all"
      //     }
      //   })
      // }
    } else {
      setGame({ game_name: t("common.all"), game_id: "all", icon: "" })
    }
  }, [OptionGame, router, router.query.game_id, t])

  const onChangeGame = (value: string) => {
    GAEventsTracker("Giftcode_filter_game")
    router.replace({
      pathname: router.pathname,
      query: {
        game_id: value
      }
    })
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (
      router.query.type === "game" &&
      isSuccess &&
      !isLoading &&
      giftcodeRef &&
      giftcodeRef.current
    ) {
      timeout = setTimeout(() => {
        if (giftcodeRef.current) {
          const element = giftcodeRef.current
          const offset = HEADER_HEIGHT
          const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset
          const offsetPosition = elementPosition - offset
          giftcodeRef.current.style.marginBottom = "250px"
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          })
        }
      }, 500)
    }

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout)
      }
    }
  }, [router, isSuccess, isLoading, giftcodeRef])

  return (
    <div className="mt-[20px] md:mt-[32px]" ref={giftcodeRef}>
      <div className="flex justify-between items-center pb-[8px]">
        <h2 className="Nunito-700 text-[16px]">{t("common.all")}</h2>
        <div className="ml-auto">
          <Select
            value={game?.game_id || "all"}
            onValueChange={(value: string) => onChangeGame(value)}
          >
            <div className="relative">
              <SelectTrigger
                asChild={false}
                className="px-[8px] h-[28px] border-main-01 dark:bg-main-disable rounded-[8px] bg-bg-disable dark:bg-bg-disable/10 flex gap-[4px] items-center text-main-01 dark:text-main-disable text-[12px]"
              >
                <SelectValue placeholder={t("common.chose_game")}>
                  <div className="flex-1">{game?.game_name || ""}</div>
                </SelectValue>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M5.8335 8.3335L10.0002 11.6668L14.1668 8.3335"
                    stroke="#7673E6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SelectTrigger>
            </div>

            <SelectContent align="end" className="p-1 rounded-[16px]">
              {OptionGame &&
                [
                  {
                    game_name: t("common.all"),
                    game_id: "all",
                    icon: ""
                  },
                  ...OptionGame.sort((a, b) =>
                    a.game_name.localeCompare(b.game_name)
                  )
                ]?.map((item: any) => (
                  <SelectItem
                    value={item?.game_id}
                    key={item?.game_id}
                    className="p-[6px] h-[36px] rounded-[12px] hover:!bg-bg-disable hover:!text-main-01 Nunito-500 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
                  >
                    <div
                      className="flex items-center gap-[6px]"
                      title={item?.game_name}
                    >
                      {item?.icon && (
                        <img
                          src={item?.icon}
                          alt={item?.game_name}
                          className="w-[24px] h-[24px] rounded-[6px]"
                        />
                      )}
                      <p className="flex-1 line-clamp-1">{item?.game_name}</p>
                    </div>
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {/* list giftcode */}

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-[12px]">
        {data?.map((item) => {
          return <CardGiftcode item={item} key={`${item?.id}-all-giftcode`} />
        })}
      </div>

      {data?.length === 0 && (
        <div className="mt-[80px]">
          <DataEmpty />
        </div>
      )}
    </div>
  )
}
