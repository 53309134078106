import { AppContext } from "app/context/AppContext"
import { useTranslation } from "next-i18next"
import { useContext } from "react"

interface HeaderProps {
  isInpage: boolean
  heading?: string
  action?: any
  customIcon?: any
  customAction?: any
}
const HeaderCustomMobile = ({
  isInpage,
  heading,
  action,
  customIcon,
  customAction
}: HeaderProps) => {
  const { t } = useTranslation()
  const { themeApp } = useContext(AppContext)

  return (
    <>
      <div
        className={`md:hidden flex fixed top-0 left-0 w-full z-[50] bg-white dark:bg-bg-header h-[60px] sm:h-[70px] items-center gap-2 justify-between px-5`}
      >
        {!isInpage ? (
          <h1 className="Nunito-700 text-[24px] dark:text-white-color">
            {heading}
          </h1>
        ) : (
          <div className="flex gap-2 items-center w-full">
            <button
              type="button"
              onClick={action}
              className="bg-bg-button dark:bg-white/10 rounded-[12px] p-[4px]"
            >
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                  d="M14 7.5L10 12.5L14 17.5"
                  stroke={themeApp === "dark" ? "#FFFFFF" : "#100D28"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <span className="Nunito-700 text-[16px] dark:text-main-white flex-1 text-center">
              {heading}
            </span>

            <div className="cursor-pointer" onClick={customAction}>
              {customIcon}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default HeaderCustomMobile
