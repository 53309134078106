import React from "react"

export const IconReceivedGiftcode = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49984 17.5C3.65889 17.5 2.1665 16.0076 2.1665 14.1667V13.3333C2.1665 12.8731 2.54984 12.5136 2.98475 12.363C3.96362 12.0241 4.6665 11.0941 4.6665 10C4.6665 8.90585 3.96362 7.97588 2.98475 7.63698C2.54984 7.4864 2.1665 7.1269 2.1665 6.66667V5.83333C2.1665 3.99238 3.65889 2.5 5.49984 2.5H15.4998C17.3408 2.5 18.8332 3.99238 18.8332 5.83333V6.66667C18.8332 7.1269 18.4498 7.4864 18.0149 7.63698C17.0361 7.97588 16.3332 8.90585 16.3332 10C16.3332 11.0941 17.0361 12.0241 18.0149 12.363C18.4498 12.5136 18.8332 12.8731 18.8332 13.3333V14.1667C18.8332 16.0076 17.3408 17.5 15.4998 17.5H5.49984ZM7.99984 8.33333C8.46007 8.33333 8.83317 7.96024 8.83317 7.5C8.83317 7.03976 8.46007 6.66667 7.99984 6.66667C7.5396 6.66667 7.1665 7.03976 7.1665 7.5C7.1665 7.96024 7.5396 8.33333 7.99984 8.33333ZM13.8332 12.5C13.8332 12.9602 13.4601 13.3333 12.9998 13.3333C12.5396 13.3333 12.1665 12.9602 12.1665 12.5C12.1665 12.0398 12.5396 11.6667 12.9998 11.6667C13.4601 11.6667 13.8332 12.0398 13.8332 12.5ZM13.4418 7.94194C13.6859 7.69786 13.6859 7.30214 13.4418 7.05806C13.1977 6.81398 12.802 6.81398 12.5579 7.05806L7.5579 12.0581C7.31382 12.3021 7.31382 12.6979 7.5579 12.9419C7.80197 13.186 8.1977 13.186 8.44178 12.9419L13.4418 7.94194Z"
        fill="#7673E6"
      />
    </svg>
  )
}
